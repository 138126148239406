import React from "react"
import { Link } from "gatsby"
import brandLogo from "../../styles/images/brand-logo.png"

export default function Footbar() {
    return (
        <>
            <div className="foot_info">
                <div className="container-fluid">
                    <nav className="navbar navbar-menu">
                        <nav className="container-fluid">
                            <div className="navbar-footer">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-foot" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
					                <span className="icon-bar"></span>
					                <span className="icon-bar"></span>
					                <span className="icon-bar"></span>
                                </button>
                            </div>
                            <div id="navbar-foot">
                                <ul className="nav navbar-nav">
                                    <li><a href="/belfold">Belföld</a></li>
                                    <li><a href="/kulfold">Külföld</a></li>
                                    <li><a href="/gazdasag">Gazdaság</a></li>
                                    <li><a href="/techtud">TechTud</a></li>
                                    <li><a href="/kultura">Kultúra</a></li>
                                    <li><a href="/sport">Sport</a></li>
                                    <li><a href="/bulvar">Bulvár</a></li>
                                    <li><a href="/eletmod">Életmód</a></li>
                                    <li><a href="/idojaras">Időjárás</a></li>
                                </ul>
                            </div>
                            <div id="navbar-foot">
                                <ul className="nav navbar-nav">
                                    <li><a href="/impresszum">Impresszum</a></li>
                                    <li><a href="/adatvedelmi-nyilatkozat">Adatvédelmi nyilatkozat</a></li>
                                </ul>
                            </div>
                        </nav>
                    </nav>
                    <div className="site-footer-brand-area-container">
                        <div className="site-footer-brand-area-logo-container">
                            <img className="site-footer-brand-area-logo" src={ brandLogo } alt=""/>
                            <p className="site-footer-brand-area-copyright"><b>Minden Percben | Mert minden perc érdekes &copy; 2020</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}