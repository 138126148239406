import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../skeleton/navbar"
import Footbar from "../skeleton/footbar"
import BodyInjections from "../injections/bodyInjections"
import HeadInjections from "../injections/headInjections"
import BoxAd from "../crumbs/boxAd"

//import { withPrefix, Link } from "gatsby"
// <script src={withPrefix('clockbox.js')} type="text/javascript" />
export default function LayoutSite({ children }) {
    return (
      <>
        <Helmet>
          <HeadInjections />          
        </Helmet>
        <div className="site">          
          <Navbar />
          <div className="home">
            <div className="wrap-news-block">
              <div className="container-fluid">
                <script mode="block" data-zone="mp_adb_970250_desktop" src="https://head-clickfusion.com/client-v2.js"></script>
                <BoxAd
                  isBlock={true}
                  ad={ <iframe src="https://mindenpercben.hu/r/1131283.html" width="1085" height="400" frameborder="0" scrolling="no"></iframe> } 
                />                
                { children }
              </div>
            </div>
          </div>
          <Footbar />
        </div>
        <BodyInjections />
      </>
    )
  }