import React from "react"
import { Link } from "gatsby"
import brandLogo from "../../styles/images/brand-logo.png"
import styled from "styled-components"

export default function Navbar() {
    const Colored = styled.p`color:#d5b700;font-size:18px;`
    const months = ["Január","Február","Március","Április","Május","Június","Július","Augusztus","Szeptember","Október","November","December"]
    const date = new Date();
    const month = date.getUTCMonth()
    const day = date.getUTCDate()
    const today = "" + months[month] + " " + day

    return (
        <>
            <div className="navigate">
                <nav className="navbar navbar-menu">
                    <div className="container">
                        <a href="/" className="navbar-brand" title="Minden Percben"><img src={ brandLogo } width="132" height="54" alt=""/></a>
                        <ul className="nav navbar-nav">
                            <li><a href="/"><Colored>{ today }</Colored></a></li>
                            <li><a href="/belfold">Belföld</a></li>
                            <li><a href="/kulfold">Külföld</a></li>
                            <li><a href="/gazdasag">Gazdaság</a></li>
                            <li><a href="/techtud">TechTud</a></li>
                            <li><a href="/kultura">Kultúra</a></li>
                            <li><a href="/sport">Sport</a></li>
                            <li><a href="/bulvar">Bulvár</a></li>
                            <li><a href="/eletmod">Életmód</a></li>
                            <li><a href="/idojaras">Időjárás</a></li>                            
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    )
}