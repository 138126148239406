import React from "react"

export default function BoxAd({ ad, isBlock }) {
    return (
        <>
            { isBlock && <> 
                <div className="wrap-news-block">
                    <div className="container-fluid">
                        <div className="row row-glairy">
                            <div className="col-glairy col-xs-12 col-md-12">
                                <div className="superBox">
                                    <div className="superBoxContainer">
                                        { ad }
                                    </div>
                                    <div className="superBoxTitle">hirdetés<i className="fa fa-arrow-circle-up"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> }
            { !isBlock && <>
                <div className="superBox">
                    <div className="superBoxContainer">
                        { ad }
                    </div>
                    <div className="superBoxTitle">hirdetés<i className="fa fa-arrow-circle-up"></i></div>
                </div>
            </> }
        </>
    )
}